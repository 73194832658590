import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Hero from "../components/hero"
import Boxes from "../components/boxes"
import CtaContact from "../components/cta-contact"
import Seo from "../components/seo"

const Retail = ({ data }) => {

  const hero = {
    'textintro': '',
    'title': 'Cutting Edge industries: Retail',
    'squares': '',
    'squaresmove': false,
  }

  const box1 = {
    'title': '',
    'content': [
      {
        'title': 'Transparency',
        'content': 'Meet your consumers increasing demands for transparency. Create powerful consumer experiences that deliver superior cross-information between groups of consumers and retailers.'
      },
      {
        'title': 'Customer',
        'content': 'The availability of transparent information, relating to consumer products, forms the foundation of new and trusted relations between our brands and our customers.',
      },
      {
        'title': 'Grow it',
        'content': 'Easily discover the likelihood of consumers opting for a recyclable pack when given the choice. Or decide to add sustainable solutions to your offering and make them available for the next interested customer.',
      }
    ],
  }

  return (
    <Layout
      linksnavcolor='black'
    >
      <Seo
        title={data.site.siteMetadata.title}
        description="'Meet your consumers increasing demands for transparency"
      />
      <Hero
        bg='linear-gradient(180deg, #ffceba 96%, #000000)'
        color='black'
        textintro={hero.textintro}
        title={hero.title}
        squares={hero.squares}
        squaresmove={hero.squaresmove}
      />
      <Boxes
        colorsquares='secondary'
        bg='linear-gradient(180deg, #000000, #1e1e1e)'
        title={box1.title}
        columns={box1.content.length}
        content={box1.content}
      />
      <CtaContact />
    </Layout>
  )
}

export default Retail

export const query = graphql`
  query Retail {
    site {
      siteMetadata {
        title
      }
    }
  }
`
